<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <div class="manager-title">{{ title }}</div>
        <div class="col-lg-6 col-12 group-button-list-top " v-if="type_status =='DELIVERY_STATUS' ">
          <div class=" style-button-tabview mr-lg-2" @click="change_tab(0)" :class="activeIndex==0 ? 'active_tab':''">
            <div class="group-content-tab">
              <i class="pi pi-check"></i><span>
              {{ text_tab_success }}
            </span>
            </div>
          </div>
          <div class=" style-button-tabview ml-lg-2 mt-lg-0 mt-2" @click="change_tab(1)" :class="activeIndex==1 ? 'active_tab':''">
            <div class="group-content-tab">
              <i class="pi pi-times"></i><span>{{ text_tab_err }}</span>
            </div>
          </div>
        </div>
        <div class="p-col-12 group-button-list-top group_form_status" >
          <div class="p-card-field style-block-panel">
            <!-- <div class="p-field p-grid" v-if="(!$commonFuction.isEmpty(code))">
              <label class="p-col-fixed txt-right">Mã chủ thú cưng (*)</label>
              <div class="p-col">
                <InputText disabled="disabled" v-model="code"   class="p-col-width" />
              </div>
            </div> -->
            <div class=" block-input">
              <div class="style-col-input">
                <div v-if="type_status ==$constants.STATUS_ORDER.ORDER_HAS_BEEN_PRINTED
                || type_status ==$constants.STATUS_ORDER.CONFIRM_PREPARATION_GOODS
                || type_status ==$constants.STATUS_ORDER.GOODS_OUT_WAREHOUSE
                || type_status ==$constants.STATUS_ORDER.GOODS_LEAVE_COMPANY
                || type_status ==$constants.STATUS_ORDER.ENOUGH_DOCUMENTS_CLOSE_APPLICATION
                || type_status ==$constants.STATUS_ORDER.COORDINATION_COMPLETED
                ">
                  <div class="group-input-panel">
                    <label class="p-col-fixed txt-right">Đơn hàng</label>
                    <!-- <div class="p-col">
                      <InputText required v-model="obj_data.code"  type="text" class="p-col-width" placeholder="Nhập" /> -->
                      <!-- <Button
                        type="button"
                        icon="pi pi-plus"
                        class="p-button-success mb-lg-0 mb-2 -2 ml-3 p-2 rounded"
                        @click="add_order_list"
                        severity="success"
                        style="height: 45px; width: 45px;"
                        >          
                      </Button> -->
                    <!-- </div>
                    <Button class="style_add_order_status" icon="pi pi-plus" @click="add_order_list" /> -->
                    <div class="p-col">
                      <InputText v-focus maxlength="50"  v-model="obj_data.code" type="text" class="" style="width: 55% !important;" placeholder="Nhập " />
                      <Button
                      type="button"
                      icon="pi pi-plus"
                      class="p-button-success mb-lg-0 mb-2 ml-3 p-2 rounded"
                      @click="add_order_list"
                      severity="success"
                      style="height: 45px; width: 45px;"
                      >          
                      </Button>
                      <BarCodeScan @success_scan="success_scan"/>
                    </div>
                  </div>
                </div>
                <div v-else-if="type_status ==$constants.STATUS_ORDER.TRANSFER_HSCT_ACCOUNTING">
                  <div class="group-input-panel" >
                    <label class="p-col-fixed txt-right">Loại cập nhật <span style="color: #E62045"> *</span></label>
                    <div class="p-col">
                    <div class="field-radiobutton">
                      <RadioButton id="city1" name="city" value="0" v-model="type_transfer" @change="change_type_transfer" />
                      <label class="label_rad" for="city1">Khớp hồ sơ</label>
                    </div>
                    <div class="field-radiobutton">
                      <RadioButton id="city2" name="city" value="1" v-model="type_transfer" @change="change_type_transfer" />
                      <label class="label_rad" for="city2">Không khớp hồ sơ</label>
                    </div>
                      </div>
                  </div>
                  <div class="row pl-3 col-12 p-0 group-input-panel ">
<!--                    <div class="col-lg-3 col-12 p-0 mr-lg-3">
                      <label class="p-col-fixed txt-right">Đơn hàng</label>
                      <div class="p-col d-flex justify-content-between">
                        <InputText v-focus required v-model="obj_data.code"   type="text" class="p-col-width" placeholder="Nhập" @input="change_order_code(activeIndex)" />
                        <BarCodeScan @success_scan="success_scan"/>
                      </div>
                    </div>-->
                    <div class="col-lg-12 col-12 p-0 mt-lg-0 mt-2">
                      <label class="p-col-fixed txt-right">Đơn hàng <span style="color: #E62045"> *</span></label>
                      <div class="p-col d-flex justify-content-between">
                        <InputText v-focus required v-model="obj_data.code"   type="text" class="p-col-width" placeholder="Nhập" @input="change_order_code(activeIndex)" />
                        <BarCodeScan @success_scan="success_scan"/>
                        <Button
                            v-if="activeIndex==0"
                            type="button"
                            icon="pi pi-plus"
                            class="p-button-success mb-lg-0 mb-2 -2 ml-3 p-2 rounded"
                            @click="add_order_status_delivery(0,type_status)"
                            severity="success"
                            style="height: 45px; width: 45px;"
                        >
                        </Button>
                        <Button
                            v-if="activeIndex==1"
                            type="button"
                            icon="pi pi-plus"
                            class="p-button-success mb-lg-0 mb-2 -2 ml-3 p-2 rounded"
                            @click="add_order_status_delivery(1,type_status)"
                            severity="success"
                            style="height: 45px; width: 45px;"
                        >
                        </Button>
                      </div>

                      <!-- <Button v-if="activeIndex==0" class="style_add_order_status" icon="pi pi-plus" @click="add_order_status_delivery(0)" />
                      <Button v-if="activeIndex==1" class="style_add_order_status" icon="pi pi-plus"  @click="add_order_status_delivery(1)"  /> -->
                    </div>

                  </div>
                </div>
                <div v-else>
                  <div class="group-input-panel" v-if="activeIndex==1">
                    <label class="p-col-fixed txt-right">Lý do giao hàng không thành công</label>
                    <div class="p-col">
                      <Dropdown
                          style="width: 100%"
                          class="p-col-width style_dropdown"
                          v-model="obj_data.reason_obj" :options="list_reason" optionLabel="name" placeholder="Chọn"  />
                    </div>
                  </div>
                  <div class="group-input-panel" v-if="suggest_delivery_type_key==3">
                    <label class="p-col-fixed txt-right">Phí bưu điện</label>
                    <div class="p-col">
                      <div class="p-inputgroup">
                        <!--                    <span class="p-inputgroup-addon">$</span>-->
                        <InputNumber class="p-col-width style-input-number" :min="0"  mode="decimal" :minFractionDigits="0" placeholder="Nhập" v-model="obj_data.fee_postage" />
                        <span class="p-inputgroup-addon">đ</span>
                      </div>
                    </div>
                  </div>
                  <div class="row pl-3 col-12 p-0 group-input-panel ">
                    <div class="col-lg-3 col-12 p-0 mr-lg-3">
                      <label class="p-col-fixed txt-right">Đơn hàng</label>
                      <div class="p-col d-flex justify-content-between">
                        <InputText v-focus required v-model="obj_data.code"   type="text" class="p-col-width" placeholder="Nhập" @input="change_order_code(activeIndex)" />
                        <BarCodeScan @success_scan="success_scan"/>
                      </div>
                    </div>
                    <div class="col-lg-8 col-12 p-0 mt-lg-0 mt-2">
                      <label class="p-col-fixed txt-right">Ghi chú giao hàng</label>
                      <div class="p-col d-flex justify-content-between">
                        <InputText required v-model="obj_data.note_delivery" type="text" class="p-col-width" placeholder="Nhập" />
                      <Button
                      v-if="activeIndex==0"
                      type="button"
                      icon="pi pi-plus"
                      class="p-button-success mb-lg-0 mb-2 -2 ml-3 p-2 rounded"
                      @click="add_order_status_delivery(0)"
                      severity="success"
                      style="height: 45px; width: 45px;"
                      >
                      </Button>
                      <Button
                      v-if="activeIndex==1"
                      type="button"
                      icon="pi pi-plus"
                      class="p-button-success mb-lg-0 mb-2 -2 ml-3 p-2 rounded"
                      @click="add_order_status_delivery(1)"
                      severity="success"
                      style="height: 45px; width: 45px;"
                      >
                      </Button>
                    </div>
                      
                      <!-- <Button v-if="activeIndex==0" class="style_add_order_status" icon="pi pi-plus" @click="add_order_status_delivery(0)" />
                      <Button v-if="activeIndex==1" class="style_add_order_status" icon="pi pi-plus"  @click="add_order_status_delivery(1)"  /> -->
                    </div>

                  </div>
                </div>





                <!--            <label class="p-col-fixed txt-right">Họ tên1(*)</label>
                            <div class="p-col">
                              <InputText required v-model="user.fullname"  id="name" type="text" class="p-col-width" placeholder="Nhập tên" />
                            </div>-->
              </div>

            </div>
            <div class="p-grid block-input clear-div-custom">
            </div>
          </div>
        </div>
        <div v-if="activeIndex==0">
          <BaseDatatable
              :key="componentKey"
              :tableData="listData"
              :model="dataModel"
              :options="options"
              :actions="getRowActions()"
              :whereKeyList="whereKeyList"
              :columnsNotDisplays="columnsNotDisplays"
              v-on:action-click="onActionClicked"
          ></BaseDatatable>
        </div>
        <div v-if="activeIndex==1">
          <BaseDatatable1
              :key="componentKey1"
              :tableData="listData1"
              :model="dataModel1"
              :options="options1"
              :actions="getRowActions1()"
              :whereKeyList="whereKeyList1"
              v-on:action-click="onActionClicked1"
          ></BaseDatatable1>
        </div>
        <div class="p-col-12 group-button-list-top group_form_status" v-if="type_status ==$constants.STATUS_ORDER.TRANSFER_HSCT_ACCOUNTING" >
          <div class="p-card-field style-block-panel">
        <div class=" block-input">
          <div class="style-col-input">
        <div class="group-input-panel" >
          <label class="p-col-fixed txt-right">Ghi chú HSCT <span v-if="type_transfer=='1'" style="color: #E62045"> *</span></label>
          <div class="p-col">
            <Textarea style="width: 100%" v-model="obj_data.note_profile"  type="text" class="p-col-width" :autoResize="true"  cols="30"  />
          </div>
        </div>
          </div>
          </div>
          </div>
          </div>
        <div v-if="type_status ==$constants.STATUS_ORDER.ORDER_HAS_BEEN_PRINTED" class="style_total_confrim" >Bạn xác nhận đã in đơn soạn hàng <span class="span_total">{{listData.length}} đơn hàng.</span></div>
        <div v-else-if="type_status ==$constants.STATUS_ORDER.CONFIRM_PREPARATION_GOODS" class="style_total_confrim" >Bạn xác nhận đã in đơn soạn hàng <span class="span_total">{{listData.length}} đơn hàng.</span></div>
        <Toolbar class="fixed-bottom-toolbar-custom">
          <template slot="right">
            <Button
                :label="$constants.TEXT_BUTTON.BACK"
                @click="backToList()"
                class="p-button-warning callback-btn"
            />
            <Button
                :label="button_name"
                @click="save_status()"
                class="p-button-success main-style-button"
            />
          </template>
        </Toolbar>

<!--        <TabView class="style-tab-view" :activeIndex="activeIndex">
          <TabPanel header="">

          </TabPanel>
          <TabPanel header="">
&lt;!&ndash;            <BaseDatatable
                :key="componentKey1"
                :tableData="listData1"
                :model="dataModel1"
                :options="options1"
                :getFilterOptions="getFilterOptions"
                :actions="getRowActions()"
                :whereKeyList="whereKeyList1"
                v-on:action-click="onActionClicked"
            ></BaseDatatable>&ndash;&gt;
          </TabPanel>
        </TabView>-->

<!--        <BaseDatatable
            :key="componentKey"
            :tableData="listData"
            :model="dataModel"
            :options="options"
            :getFilterOptions="getFilterOptions"
            :actions="getRowActions()"
            :whereKeyList="whereKeyList"
            v-on:action-click="onActionClicked"
        ></BaseDatatable>-->
<!--        <div class="p-col-12 group-button-list-top" >
          <Button
              label="Xác nhận soạn hàng"
              class=" style-button-common"
          />
          <Button
              label="Xác nhận soạn hàng"
              class=" style-button-common"
          />
          <Button
              label="Xác nhận soạn hàng"
              class=" style-button-common"
          />
        </div>-->
        <!--{{listData}}-->

        <!-- <Popup
          :key="editDialogKey"
          :show="displayDialog"
          :objKey="editId"
          :objModelName="modelName"
          :mode="dialogMode"
          @onChange="onChange()"
        ></Popup> -->

        <Dialog
          header="Xóa"
          :visible.sync="showDeleteDialog"
          :contentStyle="{overflow: 'visible'}"
          :modal="true"
        >
          Bạn có chắc muốn xóa <strong>{{this.deleteName}}</strong>
          <template
            #footer
          >
            <Button label="Xóa" @click="deleteData()" icon="pi pi-check" class="p-button-danger" />
            <Button
              label="Hủy"
              @click="showDeleteDialog = false"
              icon="pi pi-times"
              class="p-button-warning callback-btn"
            />
          </template>
        </Dialog>
        <Dialog
            header="Xóa"
            :visible.sync="showDeleteDialog1"
            :contentStyle="{overflow: 'visible'}"
            :modal="true"
        >
          Bạn có chắc muốn xóa <strong></strong>
          <template
              #footer
          >
            <Button label="Xóa" @click="deleteData1()" icon="pi pi-check" class="p-button-danger" />
            <Button
                label="Hủy"
                @click="showDeleteDialog1 = false"
                icon="pi pi-times"
                class="p-button-warning"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import DataServices from '@/core/DataServices'
import BaseDatatable from './list_process_status_n'
import BaseDatatable1 from './list_process_status_1'
import ApiRepository from "@/core/ApiRepository";
import BarCodeScan from "./index_barcode.vue";
import AuthenticationApp from "@/core/AuthenticationApp";
//import moment from "moment-timezone";
//import gql from "graphql-tag";
// import Popup from './popup'
export default {
  components: {
    BaseDatatable,
    BaseDatatable1,
    BarCodeScan,
    // Popup
  },
  directives: {
    focus: {
      // Directive definition
      inserted(el) {
        // Focus the element when inserted into the DOM
        el.focus();
      }
    }
  },
  data() {
    return {
      type_transfer:null,
      columnsNotDisplays:[],
      suggest_delivery_type_key:null,
      list_map:{},
      list_reason:[],
      button_name:'',
      type_status:null,
      status_transaction:null,
      obj_data:{
        code:null
      },
      activeIndex:0,
      filterOptions: {},
      options: [],
      options1: [],
      listData: [],
      listData1: [],
      modelName: '',
      dataModel: null,
      dataModel1: null,
      title: '',
      componentKey: 0,
      componentKey1: 0,
      editId: null,
      displayDialog: false,
      editDialogKey: 0,
      dialogMode: 'edit',
      deleteId: null,
      deleteName: null,
      deleteId1: null,
      deleteName1: null,
      showDeleteDialog: false,
      showDeleteDialog1: false,
      text_tab_success: 'Giao hàng thành công',
      text_tab_err: 'Giao hàng không thành công',
      whereKeyList:{
        where_key: {
          /*accounts: {
            role: {_eq: "admin"},
            deleted: {_eq: false}
          },*/
          deleted: {_eq: false}
        }
      },
      whereKeyList1:{
        where_key: {
          /*accounts: {
            role: {_eq: "admin"},
            deleted: {_eq: false}
          },*/
          deleted: {_eq: false}
        }
      },
      page_transaction:null,
      role: {
        view: false,
        add: false,
        edit: false,
        delete: false,
      },
      show_data_table:0
    }
  },
  mounted () {
    this.initParams();
  },
  async created() {
    //this.initParams();
   /* this.show_data_table =1
    this.modelName = this.$route.meta.model;
    this.dataModel = DataServices.getModel(this.modelName);
    this.title = this.dataModel.name
    this.$store.commit('setLoading', true);*/
    if(this.$route.params.reload) {
      window.location.reload();
    }

    /*if (element){
      element.innerHTML=''
    }*/

    /*var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ORDER);
    console.log("uuwquuwqu",arr_role.indexOf(this.$constants.ROLE.DELIVERY_STATUS))
    if (arr_role.indexOf(this.$constants.ROLE.DELIVERY_STATUS) < 0){
      this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    }*/

    // if (arr_role.indexOf(this.$constants.ROLE.ORDER_HAS_BEEN_PRINTED) < 0){
    //   this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    // }
    // if (arr_role.indexOf(this.$constants.ROLE.CONFIRM_PREPARATION_GOODS) < 0){
    //   this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    // }
    // if (arr_role.indexOf(this.$constants.ROLE.GOODS_OUT_WAREHOUSE) < 0){
    //   this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    // }
    // if (arr_role.indexOf(this.$constants.ROLE.GOODS_LEAVE_COMPANY) < 0){
    //   this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    // }
    /*var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN);
    if (arr_role.indexOf(this.$constants.ROLE.VIEW) < 0){
      this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
    }
    this.role.add = (arr_role.indexOf(this.$constants.ROLE.ADD) < 0) ? false:true;
    this.role.edit = (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) ? false:true;
    this.role.delete = (arr_role.indexOf(this.$constants.ROLE.DELETE) < 0) ? false:true;*/
    //var that = this
   /* setTimeout(function () {
      that.show_data_table =1
      that.modelName = that.$route.meta.model;
      that.dataModel = DataServices.getModel(that.modelName);
      that.title = that.dataModel.name
      console.log('this.dataModel:',that.dataModel);
    }, 1000);*/
    this.list_reason =await this.list_reason_get()
  },
  watch: {
    '$route': 'initParams'
  },
  methods: {
    change_type_transfer(){
      console.log("dsddddd")
      var type_key = (this.type_transfer) ? parseInt(this.type_transfer):null;
      var listDataNew = this.$commonFuction.convertJsonObject(this.listData);
      var listData = [];
      var count_ =0 ;
      console.log("listDataNew.length:",listDataNew.length)
      for (let i = 0; i < listDataNew.length; i++) {
        var item_data = listDataNew[i]
        item_data["type_key"] = type_key;
        listData.push(item_data)
        count_++;
        console.log("count_:",count_)
      }
      this.prodcess_dislay_comlumn(listData)
      this.listData = listData;
      //this.dataModel = DataServices.getModel(this.modelName);
      this.dataModel.processData(listData)
      this.callbackOptions();
      this.componentKey++;
    },
    backToList() {
      this.$router.push({ path: "/admin/list-order"});
    },
    change_tab(activeIndex){
      this.activeIndex = activeIndex
    },
    async list_reason_get() {
      var gqLQueryListData = DataServices.getList('reason');
      var where_data = {
        active: {_eq: true},
        deleted: {_eq: false},
        type : {_eq: 2},
      }
      var resData_data = await this.$apollo.mutate({
        mutation: gqLQueryListData,
        variables: {
          where_key:where_data ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_data.data['reason'];
      return listData;

    },
    async save_status() {
     /* if (
          this.type_status ==this.$constants.STATUS_ORDER.ORDER_HAS_BEEN_PRINTED
          || this.type_status ==this.$constants.STATUS_ORDER.CONFIRM_PREPARATION_GOODS
          || this.type_status ==this.$constants.STATUS_ORDER.GOODS_OUT_WAREHOUSE
          || this.type_status ==this.$constants.STATUS_ORDER.GOODS_LEAVE_COMPANY
      ) {*/

        var dataRequest = {
          list_data_order:this.listData,
          code_change:this.type_status,
        }
        if (this.type_status =='DELIVERY_STATUS' || this.type_status ==this.$constants.STATUS_ORDER.TRANSFER_HSCT_ACCOUNTING){
          dataRequest["list_data_order_faild"] = this.listData1
        }

        if (this.listData.length == 0 && this.listData1.length == 0){
          alert('Vui lòng chọn đơn hàng!');
          return;
        }
      if ( this.type_status ==this.$constants.STATUS_ORDER.TRANSFER_HSCT_ACCOUNTING){
        if (!this.type_transfer){
          alert('Vui lòng chọn loại cập nhật!');
          return;
        }
        if (this.type_transfer =='1'){
          if (!this.obj_data.note_profile){
            alert('Vui lòng nhập ghi chú HSCT!');
            return;
          }
        }
        dataRequest["note_profile"] = this.obj_data.note_profile;
      }
        var that = this;
        this.$commonFuction.is_loading(true);
        var data_res =await this.$CoreService.update_status_multil(dataRequest);
        this.$commonFuction.is_loading(false);
        var status = data_res.status
        if (status == true){
          that.$toast.add({
            severity: "success",
            summary: "Thông báo",
            detail: "Cập nhật thành công",
            life: 3000,
          });
          window.location.href = '/admin/list-order'
         /* location.reload()*/
        }else {
          var list_err = data_res.list_err
          var message_err = data_res.message
          if (list_err && list_err.length>0){
            var text_err = "";
            if (message_err == 'UPDATE_STATUS_ERR'){
              text_err = !this.$commonFuction.isEmpty(this.$constants.STATUS_ORDER_TEXT[this.type_status]) ? this.$constants.STATUS_ORDER_TEXT[this.type_status]:'';
              text_err = this.$commonFuction.replaceAll(text_err,'#NUMBER_ERR#',list_err.length);
              text_err = this.$commonFuction.replaceAll(text_err,'#NUMBER_ORDER#',this.listData.length);
              text_err = this.$commonFuction.replaceAll(text_err,'#CODE#',list_err.join(', '));
              alert(text_err);
            }else {
              alert("Các đơn hàng không thể thay đổi trạng thái này:" + list_err.join(', '));
            }
          }else {
            that.$toast.add({
              severity: "error",
              summary: that.$constants.MSG_TEXT.TITLE_ERR_DIALOG,
              detail: that.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN,
              life: 3000,
            });
          }
        }

      //}
    },
    async update_status_multil(dataRequest) {
      try {
        const headers = {
          "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
        };
        await ApiRepository.post('/_api/order/update-status-order-multil', dataRequest, {headers});
        return {
          status:true
        }
      } catch (err) {
        var message = err.response.data;
        console.log(message);
        return {
          status:false,
          list_err:err.response.data.data
        };
      }
    },
    async map_list_id() {
      var list_map = {};
      for (var i=0;i<this.listData.length;i++){
        var id = this.listData[i].id
        list_map[id]= this.listData[i]
      }
      for (var j=0;j<this.listData1.length;j++){
        var id1 = this.listData1[j].id
        list_map[id1]= this.listData1[j]
      }
      this.list_map = list_map
    },
    async change_order_code(activeIndex) {
      console.log('activeIndexactiveIndex:',activeIndex)
      console.log('this.modelName:',this.modelName)
      if (activeIndex == 0) {
        var list_code = [];
        list_code.push(this.obj_data.code);
        var model_name = '';
        model_name = this.modelName;
        list_code.push('model_namemodel_namemodel_name:',model_name);
        var listdata_convert = await this.load_list_order_by_code(list_code, model_name)
        var item_order = (listdata_convert.length > 0) ? listdata_convert[0] : null;
        this.suggest_delivery_type_key = null;
        if (!this.$commonFuction.isEmpty(item_order)) {
          console.log('item_orderitem_order:', item_order);
          this.suggest_delivery_type_key = !this.$commonFuction.isEmpty(item_order["suggest_delivery_type"]) ? item_order["suggest_delivery_type"] : null;
        } else {
          this.suggest_delivery_type_key = null;
        }
      }else {
        this.suggest_delivery_type_key = null;
      }
      console.log('suggest_delivery_type_key:',this.suggest_delivery_type_key)
    },
    async add_order_status_delivery(type,type_status=null) {
      var list_code = [];
      var code_key = (this.obj_data.code) ? this.obj_data.code.trim():''
      list_code.push(code_key);
      this.$commonFuction.is_loading(true);
      var model_name = '';
      if (type==0){
        //thành công
        model_name = this.modelName;
      }else {
        //thất bại
        model_name = this.modelName1;
      }
      var listdata_convert =await this.load_list_order_by_code(list_code,model_name)
      var item_order = (listdata_convert.length > 0) ? listdata_convert[0]:null;
      console.log("item_orderssssas:",item_order)
      if (!item_order){
        this.$commonFuction.is_loading(false);
        alert("Đơn hàng không tồn tại!")
        this.obj_data.code = '';
        return false;
      }
      var order_id = item_order.id
      if (!this.$commonFuction.isEmpty(this.list_map[order_id])){
        this.$commonFuction.is_loading(false);
        alert("Đơn hàng đã tồn tại!")
        this.obj_data.code = '';
        return false;
      }

      var dataRequest = {
        list_data_order:[],
        list_data_order_faild:[],
        code_change:this.type_status,
      }
      if (type==0){
        dataRequest.list_data_order = [item_order]
      }else {
        dataRequest.list_data_order_faild = [item_order]
      }
      var data_res =await this.$CoreService.check_status_multil(dataRequest);
      var status = data_res.status
      if (status == false){
        var list_err = data_res.list_err
        var message_err = data_res.message
        if (list_err && list_err.length>0){
          var text_err = "";
          if (message_err == 'UPDATE_STATUS_ERR'){
            text_err = !this.$commonFuction.isEmpty(this.$constants.STATUS_ORDER_TEXT[this.type_status]) ? this.$constants.STATUS_ORDER_TEXT[this.type_status]:'';
            text_err = this.$commonFuction.replaceAll(text_err,'#NUMBER_ERR#',list_err.length);
            text_err = this.$commonFuction.replaceAll(text_err,'#NUMBER_ORDER#',this.listData.length);
            text_err = this.$commonFuction.replaceAll(text_err,'#CODE#',list_err.join(', '));
            this.$commonFuction.is_loading(false);
            alert(text_err);
            this.obj_data.code = '';
            return false;
          }else {
            this.$commonFuction.is_loading(false);
            alert("Các đơn hàng không thể thay đổi trạng thái này:" + list_err.join(', '));
            this.obj_data.code = '';
            return false;
          }
        }else {
          this.$commonFuction.is_loading(false);
          alert(this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN);
          this.obj_data.code = '';
          return false;
        }
      }


      item_order["type_key"] = (this.type_transfer) ? parseInt(this.type_transfer):null;//0.Thành công,1.Thất bại
      if (type_status == this.$constants.STATUS_ORDER.TRANSFER_HSCT_ACCOUNTING){
        //item_order["note_profile"] = this.obj_data.note_profile
        var list_docs_order = await this.$CoreService.getListOrderDocs(order_id);
        var arr_docs_sucess = []
        var arr_docs_err = []
        var arr_docs_none = []
        for (let i = 0; i < list_docs_order.length; i++) {
          var item_docs = list_docs_order[i];
          var number_request = item_docs["number_request"];
          var text_docs_sucess = `${number_request}/${number_request} ${item_docs["name"]}`;
          var text_docs_err = `0/${number_request} ${item_docs["name"]}`;
          var text_docs_none = `-/${number_request} ${item_docs["name"]}`;
          arr_docs_sucess.push(text_docs_sucess);
          arr_docs_err.push(text_docs_err);
          arr_docs_none.push(text_docs_none);
        }
        item_order["text_docs_sucess"] = (arr_docs_sucess.length>0) ? arr_docs_sucess.join('; '):"";
        item_order["text_docs_err"] = (arr_docs_err.length>0) ? arr_docs_err.join('; '):"";
        item_order["text_docs_none"] = (arr_docs_none.length>0) ? arr_docs_none.join('; '):"";
      }else {
        item_order["detail_text_key"] = this.obj_data.note_delivery
        item_order["fee_postage_text_key"] = this.obj_data.fee_postage
      }
      if (type==0){
        //thành công
        var listData = this.listData;
        //columnsNotDisplays
        listData.push(item_order);
        this.prodcess_dislay_comlumn(listData)
        this.listData = listData;
        this.dataModel = DataServices.getModel(this.modelName);
        this.dataModel.processData(listData)
        this.callbackOptions();
        this.componentKey++;
        this.$commonFuction.is_loading(false);
      }else {
        //thất bại
        console.log("obj_data.reason_obj:",this.obj_data.reason_obj);
        if (type_status != this.$constants.STATUS_ORDER.TRANSFER_HSCT_ACCOUNTING) {
          if (!this.$commonFuction.isEmpty(this.obj_data.reason_obj)) {
            item_order["reason_text_key"] = this.obj_data.reason_obj.name
            item_order["reason_id"] = this.obj_data.reason_obj.id
          } else {
            this.$commonFuction.is_loading(false);
            alert("Vui lòng chọn lý do giao hàng không thành công!")
            return false;
          }
        }
        var listData1 = this.listData1;
        listData1.push(item_order);
        this.listData1 = listData1;
        this.dataModel1 = DataServices.getModel(this.modelName1);
        this.dataModel1.processData(listData1)
        this.callbackOptions();
        this.componentKey1++;
        this.$commonFuction.is_loading(false);
      }
      this.map_list_id();
      //clear data add
      this.obj_data.code = "";
      this.obj_data.reason_obj = null;
      this.obj_data.fee_postage = null;
      this.obj_data.note_delivery = "";
      this.obj_data.note_profile = "";
    },
   prodcess_dislay_comlumn(listData) {
     var check_order_fee_postage = 0;
     for (var t=0;t<listData.length;t++){
       var suggest_delivery_type_key = !this.$commonFuction.isEmpty(listData[t]["suggest_delivery_type"]) ? listData[t]["suggest_delivery_type"] : null;
       if (suggest_delivery_type_key == 3){
         check_order_fee_postage = 1
       }
     }
     console.log("check_order_fee_postage:",check_order_fee_postage)
     if (check_order_fee_postage == 0){
       this.columnsNotDisplays.push("fee_postage_text")
     }else {
       this.columnsNotDisplays = this.columnsNotDisplays.filter(item => item !== 'fee_postage_text');
     }
    },
    success_scan(data){
      this.obj_data.code = data
    },
    async add_order_list() {
      var order_code = (this.obj_data.code) ? this.obj_data.code.trim():''
      if (!this.$commonFuction.isEmpty(order_code)){
        this.$commonFuction.is_loading(true);
        var list_code = [];
        list_code.push(order_code);
        var listdata_convert =await this.load_list_order_by_code(list_code,this.modelName)
        var item_order = (listdata_convert.length > 0) ? listdata_convert[0]:null;
        if (!item_order){
          this.$commonFuction.is_loading(false);
          alert("Đơn hàng không tồn tại!")
          this.obj_data.code = '';
          return false;
        }
        var dataRequest = {
          list_data_order:[item_order],
          code_change:this.type_status,
        }
        var data_res =await this.$CoreService.check_status_multil(dataRequest);
        var status = data_res.status
        if (status == false){
          var list_err = data_res.list_err
          var message_err = data_res.message
          if (list_err && list_err.length>0){
            var text_err = "";
            if (message_err == 'UPDATE_STATUS_ERR'){
              text_err = !this.$commonFuction.isEmpty(this.$constants.STATUS_ORDER_TEXT[this.type_status]) ? this.$constants.STATUS_ORDER_TEXT[this.type_status]:'';
              text_err = this.$commonFuction.replaceAll(text_err,'#NUMBER_ERR#',list_err.length);
              text_err = this.$commonFuction.replaceAll(text_err,'#NUMBER_ORDER#',this.listData.length);
              text_err = this.$commonFuction.replaceAll(text_err,'#CODE#',list_err.join(', '));
              this.$commonFuction.is_loading(false);
              alert(text_err);
              this.obj_data.code = '';
              return false;
            }else {
              this.$commonFuction.is_loading(false);
              alert("Các đơn hàng không thể thay đổi trạng thái này:" + list_err.join(', '));
              this.obj_data.code = '';
              return false;
            }
          }else {
            this.$commonFuction.is_loading(false);
            alert(this.$constants.MSG_TEXT.ERR_PLEASE_TRY_AGAIN);
            this.obj_data.code = '';
            return false;
          }
        }
        var order_id = item_order.id
        if (!this.$commonFuction.isEmpty(this.list_map[order_id])){
          this.$commonFuction.is_loading(false);
          alert("Đơn hàng đã tồn tại!")
          this.obj_data.code = '';
          return false;
        }
        this.$commonFuction.is_loading(false);
        var listData = this.listData;
        listData.push(item_order);
        console.log("listData:listData:",listData)
        this.listData = listData;
        this.dataModel = DataServices.getModel(this.modelName);
        this.dataModel.processData(listData)
        this.callbackOptions();
        this.componentKey++;
      }
      this.map_list_id();

      //clear data add
      this.obj_data.code = "";
      this.obj_data.reason_obj = null;
      this.obj_data.fee_postage = null;
      this.obj_data.note_delivery = "";
    },
    async initParams() {
      if (!this.$commonFuction.isEmpty(this.$route.query)){
        if (!this.$commonFuction.isEmpty(this.$route.query.status_transaction)){
          this.status_transaction = this.$route.query.status_transaction;
          var data_status = (localStorage.getItem(this.status_transaction)) ? JSON.parse(localStorage.getItem(this.status_transaction)) : {};
          console.log("data_statusdata_status:",data_status)
          var type_status = data_status.type_status
          var title_page = data_status.title_page
          var list_code = data_status.list_code
          var button_name = data_status.button_name
          this.type_status = type_status;
          this.button_name = button_name;
          if(this.type_status == this.$constants.STATUS_ORDER.TRANSFER_HSCT_ACCOUNTING){
            this.text_tab_success = 'Khớp hồ sơ';
            this.text_tab_err = 'Không khớp hồ sơ';
          }
          var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ORDER);
          console.log("arr_rolearr_rolearr_role",arr_role)
          var list_map_role = { }
          list_map_role[this.$constants.STATUS_ORDER.ORDER_HAS_BEEN_PRINTED]= this.$constants.ROLE.ORDER_HAS_BEEN_PRINTED
          list_map_role[this.$constants.STATUS_ORDER.CONFIRM_PREPARATION_GOODS]= this.$constants.ROLE.CONFIRM_PREPARATION_GOODS
          list_map_role[this.$constants.STATUS_ORDER.ENOUGH_DOCUMENTS_CLOSE_APPLICATION]= this.$constants.ROLE.ENOUGH_DOCUMENTS_CLOSE_APPLICATION
          list_map_role[this.$constants.STATUS_ORDER.COORDINATION_COMPLETED]= this.$constants.ROLE.COORDINATION_COMPLETED
          list_map_role[this.$constants.STATUS_ORDER.GOODS_OUT_WAREHOUSE]= this.$constants.ROLE.GOODS_OUT_WAREHOUSE
          list_map_role[this.$constants.STATUS_ORDER.GOODS_LEAVE_COMPANY]= this.$constants.ROLE.GOODS_LEAVE_COMPANY
          list_map_role[this.$constants.STATUS_ORDER.TRANSFER_HSCT_ACCOUNTING]= this.$constants.ROLE.TRANSFER_HSCT_ACCOUNTING
          list_map_role["DELIVERY_STATUS"]= this.$constants.ROLE.DELIVERY_STATUS
          var role_key = list_map_role[type_status]
          console.log("role_keyrole_keyrole_key:",role_key)
          console.log("arr_role.indexOf(role_key) :",arr_role.indexOf(role_key) )
          if (arr_role.indexOf(role_key) < 0){
            this.$router.push({path: this.$constants.URL_ERR.FOBIDDEN});
          }

          var modelName = ''
          var modelName1 = ''
          if (
              type_status ==this.$constants.STATUS_ORDER.ORDER_HAS_BEEN_PRINTED
              || type_status ==this.$constants.STATUS_ORDER.CONFIRM_PREPARATION_GOODS
              || type_status ==this.$constants.STATUS_ORDER.ENOUGH_DOCUMENTS_CLOSE_APPLICATION
              || type_status ==this.$constants.STATUS_ORDER.COORDINATION_COMPLETED
          ){
            modelName = "order_custom";
          }else if (
              type_status ==this.$constants.STATUS_ORDER.GOODS_OUT_WAREHOUSE
              || type_status ==this.$constants.STATUS_ORDER.GOODS_LEAVE_COMPANY
          ){
            modelName = "order_custom_warehouse";
          }else if (type_status =='DELIVERY_STATUS'){
            //nếu tình trạng giao hàng ko defaul đơn hàng khi checkbox vì phải nhập lý do
            list_code = []
            modelName = "order_custom_delivery_status";
            modelName1 = "order_custom_delivery_status_faild";
          }else if (type_status ==this.$constants.STATUS_ORDER.TRANSFER_HSCT_ACCOUNTING){
            modelName = "order_custom_transfer_hsct_accounting";
            modelName1 = "order_custom_transfer_hsct_accounting_faild";
          }

          this.$store.commit('setLoading', true);
          this.modelName = modelName;
          //lúc đầu ko hiển thị cột phí
          this.columnsNotDisplays.push("fee_postage_text")
          // if (list_code.length>0){
          var list_data_key   = await this.load_list_order_by_code(list_code,modelName)
          var list_data = []
          if (type_status == this.$constants.STATUS_ORDER.TRANSFER_HSCT_ACCOUNTING){
            for (let k = 0; k < list_data_key.length; k++) {
              var item_data = list_data_key[k]
              var order_id = item_data.id
              var list_docs_order = await this.$CoreService.getListOrderDocs(order_id);
              var arr_docs_sucess = []
              var arr_docs_err = []
              var arr_docs_none = []
              for (let i = 0; i < list_docs_order.length; i++) {
                var item_docs = list_docs_order[i];
                var number_request = item_docs["number_request"];
                var text_docs_sucess = `${number_request}/${number_request} ${item_docs["name"]}`;
                var text_docs_err = `0/${number_request} ${item_docs["name"]}`;
                var text_docs_none = `-/${number_request} ${item_docs["name"]}`;
                arr_docs_sucess.push(text_docs_sucess);
                arr_docs_err.push(text_docs_err);
                arr_docs_none.push(text_docs_none);
              }
              item_data["text_docs_sucess"] = (arr_docs_sucess.length>0) ? arr_docs_sucess.join('; '):"";
              item_data["text_docs_err"] = (arr_docs_err.length>0) ? arr_docs_err.join('; '):"";
              item_data["text_docs_none"] = (arr_docs_none.length>0) ? arr_docs_none.join('; '):"";
              list_data.push(item_data);
            }
          }else {
            list_data = list_data_key
          }

          this.listData = list_data
          //}
          console.log("asdasdasd",this.listData)
          this.dataModel = DataServices.getModel(modelName);
          console.log("this.dataModelthis.dataModel",this.dataModel)
          this.dataModel.processData(list_data)
          this.title = title_page;
          this.callbackOptions();
          this.componentKey++;
          if (!this.$commonFuction.isEmpty(modelName1)){
            this.modelName1 = modelName1;
            // if (list_code.length>0){
            var list_data1   = await this.load_list_order_by_code([],this.modelName1)
            this.listData1 = list_data1
            //}
            console.log("asdasdasd1111111111111",this.listData1)
            this.dataModel1 = DataServices.getModel(modelName1);
            console.log("this.dataModelthis.dataModel",this.dataModel1)
            this.dataModel1.processData(list_data1)
            this.callbackOptions();
            this.componentKey1++;
          }
          this.map_list_id();
        }
      }

    },
    async load_list_order_by_code(list_code,modelName) {
      //this.obj_id
      var gqLQueryListOrderDocs = DataServices.getList(modelName);
      var listDisplayCompany = AuthenticationApp.getListCompany();

      var where_user_docs = {
        code: {_in: list_code},
      }

      if (listDisplayCompany != null)
      {
        where_user_docs['company_id'] = {_in: listDisplayCompany};
      }
      var resData_docs = await this.$apollo.mutate({
        mutation: gqLQueryListOrderDocs,
        variables: {
          where_key:where_user_docs ,
          /*offset:offset,
          limit:limit,*/
          orderBy:{created_at: 'desc'}
        }
      });
      var listData = resData_docs.data['order'];
      var listdata_convert = []
      //var count_stt = 0;
      for (var i=0;i<listData.length;i++){
        var item_order = listData[i]

        /*var order_shipping_code = '';
        var name_warehouse = '';
        var detail_text = '';
        if (this.modelName=='order_custom_warehouse'){
          //lấy mã chuyến
          if (!this.$commonFuction.isEmpty(item_order.obj_order_shipping_items)){
            order_shipping_code = (!this.$commonFuction.isEmpty(item_order.obj_order_shipping_items.order_shipping)) ? item_order.obj_order_shipping_items.order_shipping.code:'';
          }
          //lấy tên kho
          if (!this.$commonFuction.isEmpty(item_order.obj_order_item)){

            name_warehouse = (!this.$commonFuction.isEmpty(item_order.obj_order_item.warehouseByWarehouseId)) ? item_order.obj_order_item.warehouseByWarehouseId.name:'';
          }
          //chi tiet
        }
        item_order["order_shipping_code"] = order_shipping_code;
        item_order["name_warehouse"] = name_warehouse;
        console.log('name_warehousename_warehouse:',name_warehouse)
        item_order["detail_text"] = detail_text;*/
        listdata_convert.push(item_order)
      }
      return listdata_convert;
    },
    onChange() {
      this.$store.commit('setLoading', true);
      this.$apollo.queries.listData.refresh()
    },
    getFilterOptions(name) {
      var field = this.dataModel.getField(name)
      var result = []
      if (field.type == 'Selection') {
        //result = field.options;
        if (field.options ) result = field.options;
        else if(field.models && this.options[name]) result = this.options[name];
      } else {
        result = this.filterOptions[name] != null ? [...this.filterOptions[name]] : []
      }
      console.log("field.type:",field.type);
      console.log("resultresultresultresult11:",result);
      return result;
    },
    getRowActions1() {
      var actions = {}
      var that = this;
      /*this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD).then( (res)  => {
       if (res){
         actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
       }
      })*/
      /* if (this.role.add) {
         actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
       }*/
      //if ((await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD))){

      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        var buttons = {}
        //buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
        /* if (that.role.edit) {
           buttons.edit = {name: 'edit', icon: 'pencil', key: 'id', path: `list-${that.modelName}/edit/`};
         }*/
        // buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `${that.modelName}/edit/`}
        // if (that.role.delete) {
        buttons.delete = {name: 'delete', icon: 'trash', key: 'id'}
        // }

        if (that.dataModel1.actions) {
          var modelaction = that.dataModel1.actions(rowData)
          Object.assign(buttons, modelaction);
        }
        return buttons
      }
      return  actions
    },
    getRowActions() {
      var actions = {}
      var that = this;
      /*this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD).then( (res)  => {
       if (res){
         actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
       }
      })*/
     /* if (this.role.add) {
        actions.header = {name: 'add', icon: 'plus', path: `list-${this.modelName}/add`};
      }*/
      //if ((await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ADMIN,this.$constants.ROLE.ADD))){

      // actions.header = { name: 'add', icon: 'plus'};
      actions.rows = function (rowData) {
        var buttons = {}
        //buttons.view = { name: 'view', icon: 'eye', key: 'id', path: `list-${that.modelName}/view/`};
       /* if (that.role.edit) {
          buttons.edit = {name: 'edit', icon: 'pencil', key: 'id', path: `list-${that.modelName}/edit/`};
        }*/
        // buttons.edit = { name: 'edit', icon: 'pencil', key: 'id', path: `${that.modelName}/edit/`}
       // if (that.role.delete) {
          buttons.delete = {name: 'delete', icon: 'trash', key: 'id'}
       // }

        if (that.dataModel.actions) {
          var modelaction = that.dataModel.actions(rowData)
          Object.assign(buttons, modelaction);
        }
        return buttons
      }
      return  actions
    },
    async callbackOptions() {
      console.log("tettststststst:ssssss")
      var fields = this.dataModel.getDisplayFields();
      console.log("tettststststst:",fields)
      var attributes = [];
      for (let index = 0; index < fields.length; index++) {
        const element = fields[index];
        if (element.models !== undefined && element.models !== null) {
          var optionsData = [];
          attributes.push(element.models.key.key);
          attributes.push(element.models.display_key);
          //var where_status = {}
          /*if( element['name']== "rel_status"){
            where_status =  {
              type: {'_eq': 'ORDER'}
            }
          }*/
          var where_ = {}
          if( element['name']== "name_group_filter"){
            where_ =  {
              active: {_eq: true},
              deleted: {_eq: false},
            }
            element.models.tableName = 'group'
          }
          console.log("element.models:",element.models);
          var {data} = await this.$apollo.query({
            query: DataServices.getList(element.models.tableName, {"fields" : attributes.join(',')}),
            variables: {where_key : where_}
          })
          if (data) {
            var result = data[Object.keys(data)];
            if (result) {
              for (let index = 0; index < result.length; index++) {
                const ele = result[index];
                optionsData.push({value: ele[attributes[0]], label: ele[attributes[1]]})
              }
            }
          }
          console.log("optionsDataoptionsData:",optionsData)
          this.options[element.key] = optionsData;
        }
        //this.componentKey++;
      }

    },
    async onActionClicked1(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      var pageTransaction = (new Date()).getTime();
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            console.log("payload.lazyParams:111"+pageTransaction,payload.lazyParams);
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          this.deleteId1 = data.data[action.key]
          this.deleteName1 = data.data.name
          this.showDeleteDialog1 = true
          break
        case 'add':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else  {
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'custom':
          await action.handler(data.data, this)
          this.componentKey++
          break
      }
    },
    async onActionClicked(payload) {
      var action = payload.action
      var data = null
      if (payload.data) {
        data = payload.data
      }
      var pageTransaction = (new Date()).getTime();
      switch (action.name) {
        case 'view':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path + data.data[action.key] })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'edit':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            console.log("payload.lazyParams:111"+pageTransaction,payload.lazyParams);
            localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
            this.$router.push({ path: action.path + data.data[action.key],query: { page_transaction: pageTransaction.toString() }  })
          } else  {
            this.editId = data.data[action.key]
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'delete':
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(payload.lazyParams));
          this.page_transaction = pageTransaction;
          this.deleteId = data.data[action.key]
          this.deleteName = data.data.name
          this.showDeleteDialog = true
          break
        case 'add':
          this.dialogMode = action.name
          if (action.path !== undefined && action.path) {
            this.$router.push({ path: action.path })
          } else  {
            this.displayDialog = true
            this.editDialogKey++
          }
          break
        case 'custom':
          await action.handler(data.data, this)
          this.componentKey++
          break
      }
    },
    async deleteListOrder(order_id) {
      var list_data = this.listData;
      var list_convert = [];
      for (var i=0;i<list_data.length;i++){
        var item_data = list_data[i];
        var id_order_check = item_data.id;
        if (order_id != id_order_check){
          list_convert.push(item_data)
        }
      }
      this.listData = list_convert
    },
    async deleteData1() {
      console.log("hhhhhhhhhhhhhhh")
      this.showDeleteDialog1 = false;
      var list_data = this.$commonFuction.convertJsonObject(this.listData1);
      var list_convert = [];
      for (var i=0;i<list_data.length;i++){
        var item_data = list_data[i];
        var id_order_check = item_data.id;
        console.log("this.deleteId1this.deleteId1:",this.deleteId1)
        if (this.deleteId1 != id_order_check){
          list_convert.push(item_data)
        }
      }
      this.listData1 = list_convert
      this.componentKey1++;
      this.map_list_id();
    },
    async deleteData() {
      this.showDeleteDialog = false;
      await this.deleteListOrder(this.deleteId)
      this.componentKey++;
      this.map_list_id();
      /*
      console.log("testttttttttt");
      this.$commonFuction.is_loading(true);
      var resDataAccount = await this.$CoreService.getAccountByUid(this.deleteId);
      if (resDataAccount && resDataAccount.length > 0){
        //res[0].id;
        // var account_id = resDataAccount[0].id;
        // var phone = this.$commonFuction.convertPhone(resDataAccount[0].account);
        var uid = this.deleteId;
        var that = this;
        //Xóa account cũ
        
        // id mới dc thêm vào
        var userUpdate = {
          'deleted': true,
        };
        var accountUpdate = {
          'deleted': true,
        }
        var queryUpdate = `
            mutation update_users_multil($users_object: users_set_input,$accounts_object: accounts_set_input,$group_user_object: group_user_set_input) {
              update_users(where: { id: {_eq: "${uid}"}}, _set: $users_object) {
                affected_rows
                returning {
                  id
                }
              }
              update_accounts(where: { uid: {_eq: "${uid}"}}, _set: $accounts_object) {
                affected_rows
                returning {
                  id
                }
              }
            }
          `;

        let variables = {
          users_object: userUpdate,
          accounts_object: accountUpdate,
        };
        that.$apollo.mutate({
          mutation: gql(queryUpdate),
          variables: variables
        }).then(async (res) => {
          console.log(res);
        }).then(() => {
          var router_back = {path: '/admin/list-users'};
          if (!that.$commonFuction.isEmpty(router_back)){
            console.log("that.page_transaction:",that.page_transaction);
            router_back.query = { page_transaction: that.page_transaction.toString() };
          }
          that.$router.push(router_back);
          that.$commonFuction.is_loading(false);
          that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Xóa dữ liệu thành công', life: 3000 })
          //that.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Lưu dữ liệu thành công', life: 3000 })
          window.location.reload()
        });
      }*/
    }
  },
  /*apollo: {
    listData: {
      query() {
        this.modelName = this.$route.meta.model;
        console.log('8*************************',this.modelName);
        return DataServices.getList(this.modelName);
      },
      variables () {
        // Use vue reactive properties here
        return this.whereKeyList;
      },
      update(data) {
        console.log('this.dataModel.tableName',data);
        var listData = data[this.dataModel.tableName]
        this.title = this.dataModel.label + ' '
        this.dataModel.processData(listData)
        this.$store.commit('setLoading', false);
        this.componentKey += 1;
        console.log('data[this.dataModel.tableName]',data[this.dataModel.tableName]);
        return data[this.dataModel.tableName]
      },
      fetchPolicy: 'network-only',
      error() {
        this.$store.commit('setLoading', false);
      },
      skip() {
        return !this.modelName
      }
    }
  }*/

}
</script>
<style lang="css">
.p-calendar .p-datepicker { width: 280px; padding: 0px; padding-top: 5px;}
.p-calendar .p-datepicker td, .p-calendar .p-datepicker td a {padding: 0px !important;}
.p-calendar .p-datepicker td span {padding: 0px !important; width: 30px !important; height: 30px !important;}
.p-datepicker .p-datepicker-header{padding: 5px !important;}
/*.style-button-common{
  background: #C40380 0% 0% no-repeat padding-box;
  border-radius: 3px;
  color: #FFFFFF;
}*/
</style>
<style lang="scss">
.group-button-list-top{
  display: flex;
  justify-content: flex-start;
  padding-bottom: 16px;
  padding-right: 0px;
  padding-left: 0px;
  .style-input-number{
    width: 100%;
  }
  .style-group-row{
    padding-right: 15px !important;
  }
  .style-button-common{
    /*margin-left: 8px;*/
    margin-right: 8px;
}
  .style-button-common:last-of-type{
    margin-right: 0px;
  }
}
.style-tab-view{
  .p-tabview-panels{
    padding-left: 0px;
    padding-right: 0px;
    border: unset;
  }
}

.style-button-tabview{
  margin-right: 16px;
  position: relative;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #D0D4D9 !important;
  border-radius: 5px;
  color: #333333;
  width: 50%;
  height: 48px;
  cursor: pointer;
  .p-button-label-o{
    display: flex;
  }
  .group-content-tab{
    position: absolute;
    margin: auto;
    left: 0px;
    bottom: 0px;
    right: 0px;
    top: 0px;
    width: fit-content;
    height: fit-content;
    i{
      margin-right: 8px;
    }
    .pi-check{
      color: #00973D ;
    }
    .pi-times{
      color: #F5564E ;
    }

  }
}
.style-button-tabview.active_tab{
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 2px solid #C40380 !important;
  border-radius: 5px !important;
  font: normal normal bold 16px/21px Segoe UI;
  color: #C40380;
}
.group_form_status{

}

.style-block-panel{
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
  width: 100%;
  .block-input{
    .style-col-input{
      width: 50%;
      .p-col{
        padding-left: 0px;
        padding-right: 0px;
      }
      .group-input-panel{
        width: auto;
        position: relative;
        .style_add_order_status{
          background: #00973D 0% 0% no-repeat padding-box;
          border: 1px solid #00973D;
          border-radius: 3px;
          position: absolute;
          right: -50px;
          bottom: 8px;
        }
      }
    }
    .left-group-input{
      padding-left: 0px;
      padding-right: 16px;
      padding-top: 0px;
      float: left;
      /* padding-bottom: 0px;*/
    }
    .right-group-input{
      padding-right: 0px;
      padding-left: 16px;
      padding-top: 0px;
      float: right;
      /*padding-bottom: 0px;*/
    }
    label{
      color: #333333;
      font: normal normal bold 16px/21px Segoe UI;
      padding-bottom: 0px;
      margin-bottom: 0px;
      padding-left: 0px;
    }
    input{
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #D0D4D9;
      border-radius: 5px;
      width: 100%;/**/
    }
    textarea{
      height: 72px;
    }
  }

}
.fixed-bottom-toolbar-custom {
  background: transparent;
  border: 0 none;
  margin-top: 22px;
  border-top: 1px solid #b4b4b4;
  padding: 0;
  padding-top: 30px;
  .p-button {
    width: auto;
    margin-right: 8px;
  }
}
.style_parent_panel{
  height: 86px;
  .item-left-group{
    width: 223px;
    float: left;
    padding-right: 8px;
  }
  .item-right-group{
    width: calc(100% - 223px);
    float: right;
    padding-left: 8px;
  }
}
.style_dropdown{
  width: 100%;
}
.style_total_confrim{
  margin-top: 16px;
  .span_total{
    font: normal normal bold 16px/21px Segoe UI; letter-spacing: 0px; color: #C40380;
  }
}
@media (max-width: 960px){
  .style-block-panel{
    .block-input{
      .style-col-input{
        width: 100% !important;
        .group-input-panel{
          width: 100% !important;
        }
      }
    }
  }
  .style-button-tabview{
  // margin-right: 16px;
  width: 100%;
  display: block;
  }
  .group-button-list-top{
    display: block;
  }
}
.txt-link-code{
  color: #C40380;;
  text-decoration: underline;
  cursor: pointer !important;
}
th.txt-link-code{
  text-decoration: none!important;
}
.field-radiobutton {
  display: inline-block;
  vertical-align: middle; /* Đảm bảo các radio button và label cùng căn giữa theo chiều dọc */
  margin-right: 40px; /* Khoảng cách giữa các cặp radio button */
  .label_rad{
    margin-left: 5px;
  }
}
</style>
<style lang="css">
.style-tab-view .p-tabview-nav{
  display: none;
}
.p-dropdown-items-wrapper{
  max-width: 100%!important;
}
.style_note_docs div span{
  -webkit-line-clamp: 50;
}
</style>